$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});

// AJAX отправка сообщения
$(document).ready(function () {
  $('form.js-ajax-form').submit(function (event) {
    event.preventDefault();

    let _this = $(this)
    let formID = $(this).attr('id');
    let formNm = $('#' + formID);
    let form = document.getElementById(formID) // pureJS
    const formWrapper = formNm.closest(".js-ajax-form-wrapper")

    formWrapper.addClass('inloading')
    // _this.addClass('inloading')

    // получаем значение опции редиректа
    let redirect = false
    if (form.elements["redirect"]) {
      redirect = form.elements["redirect"].value
    }
    // console.log('[INFO] redirect', redirect);

    // получаем значение опции яндекс цели
    let ym_id = false
    let ym_target = false
    if (form.elements["ym_id"]) {
      ym_id = form.elements["ym_id"].value // ID счетчика
      ym_target = form.elements["ym_target"].value // Название цели
    }
    // console.log('[INFO] ym_id', ym_id);

    // Сериализуем поля
    // let inputs = document.querySelectorAll('#' + formID + ' [data-label]')
    let inputs = _this.find('[data-label]')
    let serializeObj = {}
    let serializeArr = []
    let i = 0
    for (let input of inputs) {
      let label = input.dataset.label
      let value = input.value

      let item = {
        'label': label,
        'value': value
      }

      // serializeArr.push(item)
      // serializeObj.push(item)
      serializeObj[i] = item;
      i = i + 1

      // if (obj[key] !== undefined) {
      //   if (!Array.isArray(obj[key])) {
      //     obj[key] = [obj[key]];
      //   }
      //   obj[key].push(value);
      // } else {
      //   obj[key] = value;
      // }
    }

    // console.log('[INFO] serializeObj', serializeObj)

    // Convert Form To JSON
    function convertFormToJSON(form) {
      const array = $(form)
        .serializeArray(); // Encodes the set of form elements as an array of names and values.
      const json = {};
      $.each(array, function () {
        json[this.name] = this.value || "";
      });
      return json;
    }

    // Формируем сообщение
    let mail = '';
    mail += '<h2>Заявка с сайта</h2><br><br>';

    for (const [index, item] of Object.entries(serializeObj)) {
      let label = item.label
      let value = item.value
      console.log(`${label}: ${value}`);
      mail += '<b>' + label + ":</b> " + value + '<br>';
    }

    // Show an element
    var show = function (elem) {
      elem.style.display = 'block';
    };

    // Hide an element
    var hide = function (elem) {
      elem.style.display = 'none';
    };

    // Toggle element visibility
    var toggle = function (elem) {

      // If the element is visible, hide it
      if (window.getComputedStyle(elem).display === 'block') {
        hide(elem);
        return;
      }

      // Otherwise, show it
      show(elem);
    };

    function showSuccessMessage() {
      let formMessageWrapper = form.closest(".js-ajax-form-wrapper")
      let successMessage = formMessageWrapper.querySelector('.js-ajax-success')
      show(successMessage)
      hide(form)
      // _this.html(data); // выводим в форму сообщение
    }

    $.ajax({
      type: "POST",
      url: '/mailer/sender.php',
      data: {
        mail: mail
      },

      success: function (data) {
        formWrapper.removeClass('inloading');

        // Отмечаем событие в метрике
        if (ym_id) {
          console.log(`[INFO] ym(${ym_id}, 'reachGoal', '${ym_target}')`);
          eval(`ym(${ym_id}, 'reachGoal', '${ym_target}')`);
        }

        // console.log('[INFO] Форма успешно оправлена');

        if (redirect) {
          window.location.replace(redirect); // редирект на страницу
        } else {
          // _this.html(data); // выводим в форму сообщение
          showSuccessMessage();
        }
      },

      error: function (jqXHR, text, error) {
        formWrapper.removeClass('inloading');
        // Вывод текста ошибки отправки
        _this.html('Ошибка отправки формы');
        console.log('[INFO] Ошибка отправки формы', error);
      }
    });

    return false;
  });
});